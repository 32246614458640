<template>
  <div
    class="c-app flex-row align-items-center"
    :class="{ 'c-dark-theme': darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="7">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h2>Administrative Login</h2>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Username"
                    v-model="userName"
                    required
                    autofocus
                    type="text"
                    autocomplete="username email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                    required
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        @click="handleLogin"
                        class="px-4"
                      >
                        Login
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>

            <CCard class="d-md-down-none">
              <img
                src="https://img.freepik.com/free-vector/flat-customer-support-illustration_23-2148899114.jpg?t=st=1655291747~exp=1655292347~hmac=f71f2ba6ac85f13314dc48e6f2008419290377b26fb99ba0d422aaa3fad1d2b7&w=740"
                alt="admin image"
                width="100%"
                height="334"
              />
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'Login',
  data() {
    return {
      userName: '',
      password: '',
    };
  },
  created() {
    this.getUserTheme();
  },
  computed: {
    ...mapState('coreuiStore', ['darkMode']),
    ...mapGetters('auth', ['processing']),
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('coreuiStore', ['setAction']),

    getUserTheme() {
      const userTheme = StorageService.getItem('appTheme');

      if (userTheme === null || userTheme === undefined) return;

      this.setAction(['darkMode', userTheme == 'true']);
    },

    async handleLogin() {
      if (this.userName != '' && this.password != '') {
        const requestData = {
          username: this.userName,
          password: this.password,
        };

        let status = await this.login({ data: requestData });
        if (!status) {
          this.loginText = '';
          let interval = setInterval(() => {
            this.loginText = 'Login';
            this.flashError = false;
            clearInterval(interval);
          }, 1000);
        } else {
          //notifMsgs.success = 'You have been authenticated successfully!'
          //this.showLoginSuccess({message: this.serverResponse.message})
        }
      } else {
        alert('Kindly enter username and password');
      }
    },
  },
};
</script>
